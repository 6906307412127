import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import { FormContainer } from "core/components/Form";
import { updateAliases } from "modules/config/actions";
import UpdateAliasesForm from "modules/config/UpdateAliasesForm";
import NPIValidationForm from "modules/config/NPIValidationForm";
import { Utils } from "common";
import CloseButton from "core/components/CloseButton";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const UpdateAliases = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { npi, orgId: organizationId } = useParams();
  const [urlSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries([...urlSearchParams]);
  const defaultNPIs = npi || location.state?.npis?.join("\n") || "";
  const queryKey = location.state?.queryKey;

  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const apiCall = useAuthenticatedMutation((user) =>
    updateAliases({ ...user, organizationId })
  );
  const mutation = useMutation({
    mutationFn: apiCall,
    onMutate: async (data) => {
      toaster.info({
        message: "Aliases are being added/modified.",
        isInfinite: true,
      });
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snaphot the previous value
      const previousAliasData = queryClient.getQueryData(queryKey);

      // Optimistically update
      queryClient.setQueryData(
        queryKey,
        (
          previousData = {
            rows: [],
            pagination: { page_previous: "", page_next: "" },
          }
        ) => {
          const previousAliases =
            previousData?.rows.reduce((map, current) => {
              return {
                ...map,
                [current.provider_npi]: current,
              };
            }, {}) || {};
          const previousKeys = Object.keys(previousAliases);
          const updatedAliases = data.aliases;
          const updatedKeys = Object.keys(updatedAliases);
          const combinedKeys = [...new Set([...previousKeys, ...updatedKeys])];

          const finalUpdatedRows = combinedKeys
            .map((key) => {
              const isNewRecord = !previousKeys.includes(key);
              const isUpdatedRecord = updatedKeys.includes(key);

              if (isNewRecord) {
                const newRecord = updatedAliases[key];

                return {
                  provider_npi: key,
                  name: "Fetching...",
                  isUpdated: true,
                  ...newRecord,
                };
              }

              if (isUpdatedRecord) {
                const previousRecord = previousAliases[key];
                const updatedRecord = updatedAliases[key];

                return {
                  isUpdated: true,
                  ...previousRecord,
                  ...updatedRecord,
                };
              }

              const sameRecord = previousAliases[key];
              return sameRecord;
            })
            .slice(0, 18);

          return { ...previousData, rows: finalUpdatedRows };
        }
      );

      closeForm();
      return { previousAliasData };
    },
    onSettled: (data, error, variables, context) => {
      toaster.clear();
      queryClient.invalidateQueries({ queryKey });

      if (error) {
        toaster.error({ message: error.message });
        queryClient.setQueryData(queryKey, context.previousAliasData);
      } else {
        toaster.success({
          message: "Aliases have been added/modified.",
        });
      }
    },
  });

  const [formStep, setFormStep] = useState(0);
  const [formOptions, setFormOptions] = useState({});

  const [validNPIs, setValidNPIs] = useState([]);
  const [invalidNPIs, setInvalidNPIs] = useState([]);

  const checkNPIs = (payload) => {
    const { npis, healthcare_organization_name, practice_name, provider_name } =
      payload;

    const formOptions = {};
    formOptions["healthcare_organization_name"] = healthcare_organization_name;
    formOptions["practice_name"] = practice_name;
    formOptions["provider_name"] = provider_name;

    setFormOptions(formOptions);

    const newlineSplitNPIs = npis.split("\n");
    const trimmedList = newlineSplitNPIs
      .filter((npi) => npi)
      .map((npi) => npi.trim());

    if (trimmedList.length === 0) return "Add NPIs to complete this form.";

    // Validate NPI list
    let valid = [];
    let invalid = [];
    trimmedList.forEach((npi) => {
      const isValidNpi = Utils.validateNPI(npi);

      if (isValidNpi && !valid.includes(npi)) {
        valid.push(npi);
      } else if (!isValidNpi && !invalid.includes(npi)) {
        invalid.push(npi);
      }
    });

    // Add valid NPIs to validNPIs state
    setValidNPIs(valid);
    // Add invalid NPIs to invalidNPIs state
    setInvalidNPIs(invalid);

    if (invalid.length) {
      setFormStep(1);
      return false;
    } else {
      // Go ahead and execute the mutation if there are no invalid npis
      executeMutation(valid, formOptions);
    }

    return true;
  };

  const executeMutation = async (npis, formOptions) => {
    const aliases = npis.reduce((paramsObject, currentNpi) => {
      paramsObject[currentNpi] = formOptions;
      return paramsObject;
    }, {});
    mutation.mutate({ aliases });
    closeForm();
  };

  useEffect(() => {
    window.history.pushState({ from: "Aliases" }, "");
  }, []);

  const closeForm = () => {
    navigate(`/config/${organizationId}/managed-providers/aliases`);
  };

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <Grid justify="center">
        <GridColumn>
          <FormContainer
            style={{
              paddingTop: "100px",
              minWidth: "500px",
            }}
          >
            <CloseButton onClose={closeForm} />

            {formStep === 0 && (
              <>
                <Typography variant="h2">Add/Modify NPI Aliases</Typography>

                <Typography variant="p">
                  Enter a list of NPI numbers you want to add or modify. Enter
                  one NPI per line.
                </Typography>
                <UpdateAliasesForm
                  onSubmit={checkNPIs}
                  onCancel={closeForm}
                  defaultValues={{
                    npis: defaultNPIs,
                    provider_name: searchParams.provider_name || "",
                    practice_name: searchParams.practice_name || "",
                    healthcare_organization_name:
                      searchParams.healthcare_organization_name || "",
                  }}
                />
              </>
            )}
            {formStep === 1 && (
              <NPIValidationForm
                invalidNPIs={invalidNPIs}
                validNPIs={validNPIs}
                onCancel={closeForm}
                onSubmit={(correctedNPIs) =>
                  executeMutation(validNPIs.concat(correctedNPIs), formOptions)
                }
                isSubmitting={mutation.isPending}
              />
            )}
          </FormContainer>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default UpdateAliases;
