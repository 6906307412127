import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import { FormContainer } from "core/components/Form";
import { updateRelationships } from "modules/config/actions";
import UpdateRelationshipsForm from "modules/config/UpdateRelationshipsForm";
import NPIValidationForm from "modules/config/NPIValidationForm";
import { Utils } from "common";
import CloseButton from "core/components/CloseButton";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const UpdateRelationships = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { npi, orgId: organizationId } = useParams();
  const [urlSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries([...urlSearchParams]);
  const defaultNPIs = npi || location.state?.npis?.join("\n") || "";
  const networkStatusDefault = Object.hasOwn(
    searchParams,
    "is_provider_in_network"
  )
    ? searchParams.is_provider_in_network === "true" || false
    : "";
  const providerParticipationDefaults = {
    is_in_program_ipa: searchParams.is_in_program_ipa === "true" || false,
    is_in_program_cin: searchParams.is_in_program_cin === "true" || false,
    is_in_program_pho: searchParams.is_in_program_pho === "true" || false,
    is_in_program_aco: searchParams.is_in_program_aco === "true" || false,
    is_in_program_mco: searchParams.is_in_program_mco === "true" || false,
    is_in_program_mso: searchParams.is_in_program_mso === "true" || false,
    is_in_program_ism: searchParams.is_in_program_ism === "true" || false,
  };
  const queryKey = location.state?.queryKey;

  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const apiCall = useAuthenticatedMutation((user) =>
    updateRelationships({ ...user, organizationId })
  );
  const mutation = useMutation({
    mutationFn: apiCall,
    onMutate: async (data) => {
      toaster.info({
        message: "Relationships are being added/modified.",
        isInfinite: true,
      });

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snaphot the previous value
      const previousRelationshipData = queryClient.getQueryData(queryKey);

      // Optimistically update
      queryClient.setQueryData(queryKey, (previousData) => {
        // Array
        const previousRelationships = previousData.rows.reduce(
          (map, current) => {
            return {
              ...map,
              [current.provider_npi]: current,
            };
          },
          {}
        );
        const previousKeys = Object.keys(previousRelationships);
        const updatedRelationships = data.relationships;
        const updatedKeys = Object.keys(updatedRelationships);
        const combinedKeys = [...new Set([...previousKeys, ...updatedKeys])];

        const finalUpdatedRows = combinedKeys
          .map((key) => {
            const isNewRecord = !previousKeys.includes(key);
            const isUpdatedRecord = updatedKeys.includes(key);

            if (isNewRecord) {
              const newRecord = updatedRelationships[key];

              return {
                provider_npi: key,
                name: "Fetching...",
                isUpdated: true,
                ...newRecord,
              };
            }

            if (isUpdatedRecord) {
              const previousRecord = previousRelationships[key];
              const updatedRecord = updatedRelationships[key];

              return {
                isUpdated: true,
                ...previousRecord,
                ...updatedRecord,
              };
            }

            const sameRecord = previousRelationships[key];
            return sameRecord;
          })
          .slice(0, 18);

        return { ...previousData, rows: finalUpdatedRows };
      });

      closeForm();
      return { previousRelationshipData };
    },
    onSettled: (data, error, variables, context) => {
      toaster.clear();
      queryClient.invalidateQueries({ queryKey });

      if (error) {
        console.log({ message: error });
        toaster.error({ message: error.message });
        queryClient.setQueryData(queryKey, context.previousRelationshipData);
      } else {
        toaster.success({
          message: "Relationships have been added/modified.",
        });
      }
    },
  });

  const [formStep, setFormStep] = useState(0);
  const [formOptions, setFormOptions] = useState({});

  const [validNPIs, setValidNPIs] = useState([]);
  const [invalidNPIs, setInvalidNPIs] = useState([]);

  const checkNPIs = (payload) => {
    const {
      npis,
      is_provider_in_network,
      provider_affiliation,
      ...providerParticipations
    } = payload;

    const formOptions = { ...providerParticipations };

    if (
      Object.hasOwn(payload, "is_provider_in_network") &&
      is_provider_in_network !== ""
    ) {
      formOptions["is_provider_in_network"] = String(is_provider_in_network);
    }
    if (provider_affiliation) {
      formOptions["provider_affiliation"] = provider_affiliation;
    }
    setFormOptions(formOptions);

    const newlineSplitNPIs = npis.split("\n");
    const trimmedList = newlineSplitNPIs
      .filter((npi) => npi)
      .map((npi) => npi.trim());

    if (trimmedList.length === 0) return "Add NPIs to complete this form.";

    // Validate NPI list
    let valid = [];
    let invalid = [];
    trimmedList.forEach((npi) => {
      const isValidNpi = Utils.validateNPI(npi);

      if (isValidNpi && !valid.includes(npi)) {
        valid.push(npi);
      } else if (!isValidNpi && !invalid.includes(npi)) {
        invalid.push(npi);
      }
    });

    // Add valid NPIs to validNPIs state
    setValidNPIs(valid);
    // Add invalid NPIs to invalidNPIs state
    setInvalidNPIs(invalid);

    if (invalid.length) {
      setFormStep(1);
      return false;
    } else {
      // Go ahead and execute the mutation if there are no invalid npis
      executeMutation(valid, formOptions);
    }

    return true;
  };

  const executeMutation = (npis, formOptions) => {
    const relationships = npis.reduce((paramsObject, currentNpi) => {
      paramsObject[currentNpi] = formOptions;
      return paramsObject;
    }, {});

    mutation.mutate({ relationships });
    closeForm();
  };

  useEffect(() => {
    window.history.pushState({ from: "Relationships" }, "");
  }, []);

  const closeForm = () => {
    navigate(`/config/${organizationId}/managed-providers/relationships`);
  };

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <Grid justify="center">
        <GridColumn>
          <FormContainer
            style={{
              paddingTop: "100px",
              minWidth: "500px",
            }}
          >
            <CloseButton onClose={closeForm} />

            {formStep === 0 && (
              <>
                <Typography variant="h2">
                  Add/Modify NPI Relationships
                </Typography>

                <Typography variant="p">
                  Enter a list of NPI numbers you want to add or modify. Enter
                  one NPI per line.
                </Typography>

                <UpdateRelationshipsForm
                  onSubmit={checkNPIs}
                  onCancel={closeForm}
                  defaultValues={{
                    npis: defaultNPIs,
                    is_provider_in_network: networkStatusDefault,
                    provider_affiliation:
                      searchParams.provider_affiliation || "",
                    ...providerParticipationDefaults,
                  }}
                />
              </>
            )}
            {formStep === 1 && (
              <NPIValidationForm
                invalidNPIs={invalidNPIs}
                validNPIs={validNPIs}
                onCancel={closeForm}
                onSubmit={(correctedNPIs) =>
                  executeMutation(validNPIs.concat(correctedNPIs), formOptions)
                }
                isSubmitting={mutation.isPending}
              />
            )}
          </FormContainer>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default UpdateRelationships;
